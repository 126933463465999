import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    apiEndpoint = environment.apiEndpoint;
    timeZone: string;
    constructor(private http: HttpClient) {
        if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
            this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    }

    postData(url, data) {
        return this.http.post<any>(this.apiEndpoint + url, data, { reportProgress: true });
    }


    public deviceToken = new BehaviorSubject<any>({});
    public deviceToken$ = this.deviceToken.asObservable();


    public sendChat = new BehaviorSubject<any>({});
    public sendChat$ = this.sendChat.asObservable();


    // public userImage = new BehaviorSubject<any>({});
    // public userImage$ = this.userImage.asObservable();


    uploadImage(url: string, data) {
        const fd = new FormData();
        fd.append('file', data.file);
        fd.append('fileOf', data.fileOf);
        return this.http.post<any>(this.apiEndpoint + url, fd);
    }


    getData(url, obj, loader?) {
        let headers = new HttpHeaders().set('timezone', this.timeZone); // create header object
        let params: any = new HttpParams();
        Object.keys(obj).forEach(key => {
            if (obj[key] !== '' && obj[key] !== undefined) {
                params = params.set(key, obj[key]);
            }
        });
        params.timeZone = this.timeZone;
        return this.http.get<any>(this.apiEndpoint + url, { params: params, reportProgress: loader });
    }

    putData(url: string, data: any) {
        return this.http.put<any>(this.apiEndpoint + url, data, { reportProgress: true });
    }
    appendFormData(myFormData: { [x: string]: any; }): FormData {
        const fd = new FormData();
        for (const key of Object.keys(myFormData)) {
            fd.append(key, myFormData[key]);
        }
        return fd;
    }
}
