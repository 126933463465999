import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from "ngx-ui-loader";
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { SubcategoriesComponent } from './modals/subcategories/subcategories.component';
import { ErrorHandlerInterceptor } from './common/ErrorHandler.interceptor';
import { TokenInterceptor } from './common/token.interceptor';
import { UserService } from './services/user.service';

import { NgxStarRatingModule } from 'ngx-star-rating';

// firebase
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { SocketIoModule} from 'ngx-socket-io';
import { SocketsService } from './services/sockets/sockets.service';
import { SocketConnection } from './services/sockets/socket-connection.service';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 10,
  bgsType: SPINNER.rectangleBouncePulseOutRapid, // background spinner type
  fgsType: SPINNER.rectangleBouncePulseOutRapid, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 3, // progress bar thickness
};

@NgModule({
  declarations: [
    AppComponent,
    SubcategoriesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SocketIoModule,
    AppRoutingModule,
    AngularFireDatabaseModule,
    NgxStarRatingModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxIntlTelInputModule,
    SlickCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GoogleConsoleKey,
      libraries: ['places']
    })
  ],
  entryComponents: [SubcategoriesComponent],
  providers: [UserService,
    SocketsService,
    SocketConnection,
     {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorHandlerInterceptor,
    multi: true
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { 

}
