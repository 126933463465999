import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private commonService: CommonService,
        public router: Router,
        private userService: UserService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        const token = this.userService.getUserToken;
        if (token) {
            req = req.clone({ headers: req.headers.set('authorization', 'bearer ' + token) });
        }

        if (req.reportProgress === true) {
            this.commonService.loaderStart();
        }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.commonService.loaderEnd();
            }
        },
            (err: any) => {
                this.commonService.loaderStart();
                if (err instanceof HttpErrorResponse) {
                    if (err.error.statusCode === 401) {
                        this.userService.userSignOut();
                        this.router.navigate(['login']);
                    } else {
                        this.commonService.loaderEnd();
                        this.commonService.toastError(err ? err.error.message :'Something went wrong');
                    }
                }
            }));
    }
}
