import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor() { }

    getItem(key: string): any {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }

    setItem(key: string, value: string): void {
        try {
            window.localStorage.setItem(key, value);
        } catch (err) {
        }
    }

    update(id: string, value: any): any {
        try {
            if (!value) {
                window.localStorage.removeItem(id);
            } else {
                window.localStorage.setItem(id, JSON.stringify(value));
            }
            return value;
        } catch (err) {

            
        }
    }

    clear(): void {
        return window.localStorage.clear();
    }

    removeItem(id: string) {
        window.localStorage.removeItem(id);
    }

    // private _isQuotaExceeded(e): boolean {
    //   var quotaExceeded = false;
    //   if (e) {
    //     if (e.code) {
    //       switch (e.code) {
    //         case 22:
    //           quotaExceeded = true;
    //           break;
    //         case 1014:
    //           // Firefox
    //           if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
    //             quotaExceeded = true;
    //           }
    //           break;
    //       }
    //     } else if (e.number === -2147024882) {
    //       // Internet Explorer 8
    //       quotaExceeded = true;
    //     }
    //   }
    //   return quotaExceeded;
    // }
}
