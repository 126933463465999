import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SocketConnection } from './socket-connection.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SocketsService {

  constructor(private socket: SocketConnection) { }

  removeListener(eventName: string) {
    this.socket.removeAllListeners(eventName);
  }

  sendMessage(obj: any) {
    return new Observable<any>(observer => {
      this.socket.emit('SendMessageCustomerVendor', obj, (data: any) => {
        observer.next(data);
      });
    });
  }

  sendMessageWithoutSubscribe(obj: any) {
    this.socket.emit('SendMessageCustomerVendor', obj);
  }

  getMessage(): Observable<any> {
    return this.socket
      .fromEvent("SendMessageCustomerVendor").pipe(
        map(data => {
          return data;
        }));
  }

  onlineOffline(obj) {
    this.socket.emit('online-status', obj);
  }

  checkOnline(): Observable<any> {
    return this.socket
      .fromEvent("online-status").pipe(
        map(data => {
          return data;
        }));
  }

}