import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { UserService } from '../services/user.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    timeZone: string;

    constructor(private commonService: CommonService,
        public router: Router,
        private userService: UserService,
        private localStorage: LocalStorageService
    ) {
        if (localStorage.getItem('web_user') !== null) {
            const user = this.localStorage.getItem('web_user');
            this.userService.currentUserSubject.next(user);
        }

   

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        const token = this.userService.currentUserSubject.value ? this.userService.currentUserSubject.value.accessToken : null;
        if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
            this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          }
        // if (token) {
        //     req = req.clone({
        //         headers: req.headers.set('authorization', 'bearer ' + token)
        //     });
        // }


        if (token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    // 'Access-Control-Allow-Origin':`${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
                    // 'Accept-Language': "en" + ';q=0.9,en-US,en;q=0.8'
                }
            });
        }
        if (req.reportProgress === true) {
            this.commonService.loaderStart();
        }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.commonService.loaderEnd();
            }
        },
            (err: any) => {
                this.commonService.loaderStart();
                if (err instanceof HttpErrorResponse) {
                    if (err.error.statusCode === 401) {
                        this.userService.userSignOut();
                        this.router.navigate(['login']);
                    } else {
                        // this.commonService.toastError('Something went wrong');
                    }
                }
            }));
    }
}
