import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import {ApiService} from 'src/app/services/api.service'
import {Router , ActivatedRoute} from '@angular/router'
 // import {app} from 'firebase';
declare const firebase:any

@Injectable({
  providedIn: 'root'
})

export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  messaging:any;
  route : any;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private router: Router,
    private activayedRoute: ActivatedRoute,
    private apiService : ApiService
  ) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(token) {
    const obj = {
      device_token: token,
      device_type: 'web'
    };
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        const obj = {
          device_token: token,
          device_type: 'WEB'
        };   
        this.apiService.deviceToken.next({value : obj})
      },
      (err) => {
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        if(payload['data'].type == 'TRANSACTION_FEEDBACK'){
          localStorage.setItem('notification' , JSON.stringify(payload['data']))
        }
          const NotificationOptions = {
            body: payload['data'].body,
            data: payload['data'].transaction,
            icon: '/firebase-logo.png',
            tag: payload['data'].type
          }
          navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
            registration.showNotification(payload['data'].title , NotificationOptions);
          });
      } , (error) =>{
      })
  }


  



}
