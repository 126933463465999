import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(
        private router: Router,
        private localStorage: LocalStorageService
    ) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                if (localStorage.getItem('web_user') !== null) {
                   const user = this.localStorage.getItem('web_user');
                   this.currentUserSubject.next(user);
                }
            }
        });

        /********* Check JSON parse error on fetching currentUser from local storage **********/
        let _user: any = null;
        try {
            _user = this.localStorage.getItem('web_user');
        } catch (error) {
            if (error instanceof SyntaxError) this.removeUser();
        }
        this.currentUserSubject = new BehaviorSubject<any>(_user);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    /********* Get the current value of the logged in user **********/
    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    /********* Get the current user token **********/
    public get getUserToken(): string {
        if (!!this.currentUserValue) return this.currentUserValue.accessToken;
    }

    /********* Set user in local storage **********/
    public setUserLocalData(userData: any) {
        this.localStorage.setItem('web_user', JSON.stringify(userData));
        this.currentUserSubject.next(userData);
    }


    /********* Remove user from local storage **********/
    public removeUser() {
        this.localStorage.removeItem('web_user');
        if (this.currentUserSubject) {
            this.currentUserSubject.next(null);
        }
    }

    /********* User Sign-Out **********/
    public userSignOut() {
        this.removeUser();
        this.router.navigate(['auth/login']);
    }
    
    isUserLoggedIn() {
      return  this.localStorage.getItem('web_user') ? true : false;
    }
}
