<h3 mat-dialog-title>{{data?.subCategories?.name + "'" + "Sub categories"}}</h3>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="row">
            <div class="col-md-4" *ngFor="let item of data?.subCategories?.subCategories; let i = index;">
                <div class="near-rest" routerLink="/app/retaurants/detail/{{item?.vendorId}}/{{item?._id}}">
                    <span><img src="{{item?.imageUrl?.thumbnail}}" alt=""></span>
                    <div class="near-rest-in">
                        <h5>{{item?.name}} </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center" *ngIf="data?.subCategories?.subCategories.length === 0">
                No sub Category found!
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="text-align: end;">
    <input class="btn" type="submit" name="" value="Close">
</mat-dialog-actions>