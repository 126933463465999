import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from "ngx-ui-loader";
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    constructor(private toaster: ToastrService,
        private ngxService: NgxUiLoaderService) { }
    toastSuccess(message: string) {
        return this.toaster.success(message);
    }
    toastError(message: string) {
        return this.toaster.error(message);
    }
    loaderStart() {
        return this.ngxService.start();   
    }
    loaderEnd() {
        return this.ngxService.stop();
    }
}
