import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import { UserService } from '../user.service';
// import { UtilityService } from '../utility/utility.service';

@Injectable()
export class SocketConnection extends Socket {

  constructor(
    private user: UserService
  ) {
    super({ url: `${environment.apiBaseUrlSocket}?accessToken=${user.getUserToken}`, options: {} });
  }

}