import { Component , OnInit , ElementRef , HostListener , Input  } from '@angular/core';
import { SPINNER } from 'ngx-ui-loader';
import { MessagingService } from './services/messaging.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'friendlyMe';
  SPINNER = SPINNER;
  message : any;
  // @HostListener('document:click', ['$event'])

  
  constructor(
    private fire: MessagingService,
    private eRef: ElementRef
  ) {
    
  }




  ngOnInit() { 
    this.fire.receiveMessage();
    this.fire.requestPermission();
    this.message = this.fire.currentMessage;
}


clickout(event) {
  if(this.eRef.nativeElement.contains(event.target)) {
    // this.text = "clicked inside";
  } else {
    // this.text = "clicked outside";
  }
}

  
}
