// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint : 'https://reward-platform-apidev.netsolutionindia.com/',
  GoogleConsoleKey: 'AIzaSyB_m5suYMec1OGGbOdLoIBFUC_i2V4eaRw',
  apiBaseUrlSocket: 'https://reward-platform-apidev.netsolutionindia.com/CHATNSP',
  firebase : {
    apiKey: "AIzaSyApgP08RLlIkHV77NY8dV-8cs5pyWF1MAI",
    authDomain: "friendleme-b4a79.firebaseapp.com",
    projectId: "friendleme-b4a79",
    storageBucket: "friendleme-b4a79.appspot.com",
    messagingSenderId: "736513282031",
    appId: "1:736513282031:web:6f3db0dbc67ab42b36301a",
    measurementId: "G-VXZDXZ3HZW"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
